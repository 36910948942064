import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { WidgetActions, WidgetActionTypes } from './widgets-entity.action';

export interface State extends EntityState<any> {}
export const adapter: EntityAdapter<any> = createEntityAdapter<any>();
export const initialState: State = adapter.getInitialState({});

export function widgetReducer(
  state = initialState,
  action: WidgetActions
): State {
  switch (action.type) {
    case WidgetActionTypes.AddWidget: {
      return adapter.addOne(action.payload.widget, state);
    }

    case WidgetActionTypes.UpsertWidget: {
      return adapter.upsertOne(action.payload.widget, state);
    }

    case WidgetActionTypes.AddWidgets: {
      return adapter.addMany(action.payload.widgets, state);
    }

    case WidgetActionTypes.UpsertWidgets: {
      return adapter.upsertMany(action.payload.widgets, state);
    }

    case WidgetActionTypes.UpdateWidget: {
      return adapter.updateOne(action.payload.widget, state);
    }

    case WidgetActionTypes.UpdateWidgets: {
      return adapter.updateMany(action.payload.widgets, state);
    }

    case WidgetActionTypes.DeleteWidget: {
      return adapter.removeOne(action.payload.id, state);
    }

    case WidgetActionTypes.DeleteWidgets: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case WidgetActionTypes.ClearWidgets: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}
